import Vue from "vue";
import Vuetify from "vuetify/lib/framework";


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        textfield: "#e7edfe",
        grey: "#555555",
        white: "#ffffff",
        "white-fade": "#f5f5f5",
        primary: "#00C9FF",
        secondary: "#92FE9D",
        dark: "#052069",
        "dark-shade": "#031340",
        background: "#f5f5f5"
      },
    },
  },
});
