import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import 'animate.css';






Vue.config.productionTip = false;



// router.post('/createuser', function(req, res) {

// });


new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");


// const express = require('express')

// const app = express()

// const port = 3000

// const unirest = require("unirest");

// app.get('/', (req, res) => res.send('Testing my Geolocation Node app!'))

// const apiCall = unirest(

//   "GET",

//   "https://ip-geolocation-ipwhois-io.p.rapidapi.com/json/"

// );

// apiCall.headers({

//   "x-rapidapi-host": "ip-geolocation-ipwhois-io.p.rapidapi.com",

//   "x-rapidapi-key": "<your-api-key>"

// });

// apiCall.end(function(result) {

//   if (res.error) throw new Error(result.error);

//   console.log(result.body);

//   res.send(result.body);

// });