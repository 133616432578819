<template>
  <div class="a-social-b">
    <!-- <a href="#"><v-icon x-large color="secondary">mdi-account</v-icon></a> -->
    <a href="/SupportTeam"><v-icon x-large color="secondary">mdi-help-circle</v-icon></a>
  </div>
</template>

<script>
  export default {
  name: 'Socials'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .social-icons{
    background:#92FE9D;
}
.a-social-b{
    display: flex;
    position: fixed;
    right:32px;
    bottom: 64px;
    z-index: 999;
    border-radius: 50%;
   }

   .a-social-b:hover {
    box-shadow: var(--primary) 0 0 12px;
   }


.a-social-b a{
    border-radius: 20px;
    position: relative;
    text-decoration: none;
} 

.v-icon{
    justify-content: space-between;
    background: #031340;
    border-radius: 50%;
   }

@media screen and (max-width: 400px) {
    .a-social-b{
     position: fixed;
   }
   }
</style>
