<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}">
    <v-app-bar color="dark-shade" app elevate-on-scroll>
      <v-toolbar-title>
        <a href="./">
          <v-img
            contain
            class="logo"
            max-height="300"
            max-width="200"
            src="./assets/logo.png">
          </v-img>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs class="hidden-xs-only hidden-sm-only hidden-md-only" right>
        <v-tab class="white--text" to="/" text>
          Home
        </v-tab>
        <v-tab class="white--text" to="/features" text>
          Features
        </v-tab>
        <v-tab class="white--text" to="/accounttypes" text>
          Account Types
        </v-tab>
        <v-tab class="white--text" to="/platforms" text>
          Platforms
        </v-tab>
        <v-tab class="white--text" to="/education" text>
          Education
        </v-tab>
        <v-tab class="white--text" to="/contact" text>
          Contact
        </v-tab>
      </v-tabs>
        <div class="hidden-lg-and-up">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon class="white--text" v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-list class="responsiveMenu" style="background: #031340;">
            <v-list-item>
              <v-list-item-title>
                <v-btn class="primary--text" to="/" text>Home</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn class="primary--text" to="/features" text>Features</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn class="primary--text" to="/accounttypes" text>Account Types</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn class="primary--text" to="/platforms" text>Platforms</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn class="primary--text" to="/education" text>Education</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn class="primary--text" to="/contact" text>Contact</v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    
    <Socials/>

    <v-main>
      <router-view/>
    </v-main>
    
    <svg
      viewBox="0 0 500 150"
      preserveAspectRatio="none"
      class="container-cap">
      <path d="M-13.82,78.45 C183.68,82.39 442.71,-4.44 533.01,95.22 L500.00,150.00 L0.00,150.00 Z">
      </path>
    </svg>
    <v-footer fluid class="ma-0 pa-0" color="dark-shade">
      <v-container>
        <v-row>
          <v-col lg="6" cols="12">
            <h2 class="text-center white--text">Useful Links</h2><br>
            <ul class="text-center">
              <li style="list-style: none"><a href="/legal">Legal</a></li>
              <li style="list-style: none"><a href="/supportteam">Support</a></li>
              <li style="list-style: none"><a href="https://firebasestorage.googleapis.com/v0/b/trade245-web-apps.appspot.com/o/T245A-Legal%2FTerms%20And%20Conditions%20Of%20Use.pdf?alt=media&token=2b96d2cc-87fb-446a-9f8b-6bef3fe43e49">Terms And Conditions</a></li>
    
              <li style="list-style: none"><a href="https://firebasestorage.googleapis.com/v0/b/trade245-web-apps.appspot.com/o/T245A-Legal%2FPrivacy%20Policy.pdf?alt=media&token=68b1e24d-07d1-4138-80bf-2e3bbd2b6fae">Privacy Policy</a></li>
              <li style="list-style: none"><a href="https://firebasestorage.googleapis.com/v0/b/trade245-web-apps.appspot.com/o/T245A-Legal%2FBonus%20Policy.pdf?alt=media&token=86ae92ea-3d65-4778-844e-402fe2e55ead">Bonus Policy</a></li>
            </ul>
            <!-- <a href="https://www.facebook.com/Trade245-100349932398303">
              <v-icon x-large color="primary">mdi-facebook</v-icon>
            </a> -->
            <!-- <a href="https://www.instagram.com/trade_245/">
              <v-icon x-large color="primary">mdi-instagram</v-icon>
            </a>   -->
          </v-col>
          <v-col lg="6" cols="12">
            <v-img
              contain
              class="logo"
              max-height="200"
              max-width="200"
              src="./assets/logo.png">
            </v-img>
            <br>
            <p class="text-center-center white--text footer">
             Trade245.africa is the brand name of MARKETFINANCIALS LIMITED, a Seychelles Securities Dealer Licensee, authorised and regulated by the Seychelles Financial Services Authority (FSA) under license number SD006 and registration number 8413711-1. MARKETFINANCIALS LIMITED is governed by the Consolidated Securities Act, 2007, as amended. The website (www.trade245.africa) is operated by MARKETFINANCIALS LIMITED.
              <br><br>
              Risk Warning: Our products are traded on margin and carry a high level of risk and it is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. For more information, please read our Risk Disclosure Statement. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 75% of retail investor accounts lose money when trading CFDs . You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Please consider our Risk Disclosure.
              <br><br>
              Trading Facility is NOT available to Persons who are under the age of 18, or otherwise under legal age in their country of residence or who, otherwise, cannot form legally binding contracts under the law(s) applicable in their country of residence or, in the case of Legal Entities, in their country of formation, incorporation and/or domiciliation.
              <br><br>
               Please note that Trade245.africa does not offer services to residents from certain jurisdictions including Afghanistan, Belarus, Central African Republic, Cuba, Congo, China, Eritrea, Guinea Bissau, Iran, Iraq, Lebanon, Libya, Mali, Myanmar, North Korea, North Sudan, Russia, Somalia, South Sudan, Syria, Ukraine, USA, Venezuela, West Bank (Palestinian Territory), Yemen.
              <br><br>
              Registered address: Unit F F28 Eden Plaza, Eden Island, Republic of Seychelles
              <br><br>
              <a href="/legal">Links to Terms and Conditions Privacy Policy Risk Disclosure</a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>


<script>
  import Socials from './components/Socials.vue';

  export default {
    name: "App",
    components: {
      Socials,
    },
    data: () => ({
      drawer: false,
      group: null,
    }),
  };
</script>


<style>
  :root{
    --textfield: #e7edfe;
    --grey: #555555;
    --white: #ffffff;
    --white-fade: #f5f5f5;
    --primary: #00C9FF;
    --secondary: #92FE9D;
    --dark: #052069;
    --dark-shade: #031340;
    --background: #f5f5f5;
  }

  #hero{
    background: linear-gradient(#031340,#052069);
  }

  .container-cap {
    height: 128px;
    width: 100%;
    display: block;
  }

  .container-cap path {
    fill: var(--dark);
  }

  .v-footer{
    background: linear-gradient(#052069,#031340);
    font-size: 12px;
  }
  .v-btn--active .v-btn__content {
    color: #00c9ff;
    opacity: 100;
  }
  .v-btn--hover .v-btn__content {
    color: #00c9ff;
  }

  @media screen and (max-width: 400px) {
  .v-app-bar{
    width: 400px;
  }
    body{
      overflow: hidden;
    }
    .logo{
      height: 30px;
      width: 120px;
    }
  }

  @media screen and (max-width: 600px) {
  .v-app-bar{
    width: 600px;
  }
    body{
      overflow: hidden;
    }
  }

  @media screen and (max-width: 1264px) {
  .v-app-bar{
    width: 1264px;
  }
    body{
      overflow: hidden;
    }
  }
</style>
