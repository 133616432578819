import Vue from "vue";
import VueRouter from "vue-router";
import '../assets/css/styles.scss';
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Landing.vue"), 
  },

  {
    path: "/Features",
    name: "features",
    component: () => import("../views/Features.vue"),
  },
  {
    path: "/AccountTypes",
    name: "accounttypes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AccountTypes"),
  },
  {
    path: "/Platforms",
    name: "platforms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Platforms"),
  },
  {
    path: "/Education",
    name: "education",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Education"),
  },
  {
    path: "/Contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Contact"),
  },
  {
    path: "/EverythingTrading",
    name: "everythingtrading",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/EverythingTrading"),
  },
  {
    path: "/ForexEducation",
    name: "forexeducation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ForexEducation"),
  },
  {
    path: "/Glossary",
    name: "glossary",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Glossary"),
  },
  {
    path: "/TradingConditions",
    name: "tradingconditions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/TradingConditions"),
  },
  {
    path: "/SupportTeam",
    name: "supportteam",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SupportTeam"),
  },
  {
    path: "/Legal",
    name: "legal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Legal"),
  },
   {
    path: "/Request",
    name: "request",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Request"),
  },
  {
    path: "/createuser",
    name: "createuser",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
